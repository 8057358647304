import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import useOutOfState from './useOutOfState';
import { Close } from '@material-ui/icons';
import {
  getFromSessionStorage,
  setInSessionStorage,
} from '../../util/storage-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: `${theme.spacing(0, 1)}`,
      marginBottom: theme.spacing(2),
    },
    modal: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: '922px',
      },
    },
    closeBtn: {
      position: 'absolute',
      top: 24,
      right: 24,
      padding: 0,
    },
    popupContent: {
      padding: '0px',
    },
    popupContentArea: {
      padding: theme.spacing(1),
    },
  }),
);

const CaliforniaUsers = () => {
  const classes = useStyles();
  const { richT } = useTranslation();

  const OutOfStateModalClosed = 'OUT_OF_STATE_MODAL_CLOSED';
  const [showOutOfStatePopUp, setShowOutOfStatePopUp] = useState<boolean>(
    false,
  );

  const onClose = () => {
    setInSessionStorage(OutOfStateModalClosed, 'true');
    setShowOutOfStatePopUp(false);
  };

  const { checkIfLocationInsideCalifornia } = useOutOfState();

  useEffect(() => {
    const isOutOfStateDialogClosed = getFromSessionStorage(
      OutOfStateModalClosed,
    );

    !isOutOfStateDialogClosed &&
      checkIfLocationInsideCalifornia()
        .then(isInsideCalifornia => {
          if (isInsideCalifornia) {
            setShowOutOfStatePopUp(true);
          }
        })
        .catch(error => {
          console.error('Error checking location:', error);
        });
  }, []);

  return (
    <>
      <Dialog
        open={showOutOfStatePopUp}
        onClose={onClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={classes.modal}
        scroll="body"
        disableScrollLock
      >
        <DialogTitle>
          <Grid container justify="space-between">
            <Typography variant={'h1'} noWrap gutterBottom></Typography>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={onClose}
              className={classes.closeBtn}
            >
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.popupContent}>
          <Paper className={classes.popupContentArea}>
            <Typography variant={'body1'} component={'div'} data-testid='california-out-of-state-message'>
              {richT('CALIFORNIA_OUT_OF_STATE_MESSAGE')}
            </Typography>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CaliforniaUsers;
