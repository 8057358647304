import { getGeolocation } from '../../util/geolocationUtil';

function useOutOfState() {
  async function checkIfLocationInsideCalifornia(): Promise<boolean> {
    try {
      const { latitude, longitude } = await getGeolocation();
      // Check if the user's location is outside Oregon
      return isLocationInsideCalifornia(latitude, longitude);
    } catch (error) {
      console.error('Error occurred during geolocation retrieval:', error);
      // If any unexpected error occurs, assume the user is within Oregon state
      return false;
    }
  }

  const isLocationInsideCalifornia = (
    latitude: number,
    longitude: number,
  ): boolean => {
    // https://www.arcgis.com/sharing/rest/content/items/ca7b47512a2a442fbfa039bded0b6eaf/info/metadata/metadata.xml?format=default&output=html
    const californiaBoundingBox = {
      southLatitude: 32.423601,
      northLatitude: 42.068697,
      westLongitude: -124.513643,
      eastLongitude: -113.498407,
    };

    // Check if the latitude and longitude fall within the California bounding box
    const isInside =
      latitude >= californiaBoundingBox.southLatitude &&
      latitude <= californiaBoundingBox.northLatitude &&
      longitude >= californiaBoundingBox.westLongitude &&
      longitude <= californiaBoundingBox.eastLongitude;

    return isInside;
  };

  return {
    checkIfLocationInsideCalifornia,
  };
}

export default useOutOfState;
