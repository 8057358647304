interface GeolocationPosition {
    latitude: number;
    longitude: number;
  }

export async function getGeolocation(): Promise<GeolocationPosition> {
    return new Promise<GeolocationPosition>((resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords;
            resolve({ latitude, longitude });
          },
          error => {
            reject(error);
          },
        );
      } else {
        reject(new Error('Geolocation is not available in this browser.'));
      }
    });
  }
