import { Button, Container, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from '@reach/router';
import React, { FunctionComponent, useContext } from 'react';
import { BrowserNotice } from '../../components/browser-check';
import { useTranslation } from '../../hooks/useTranslation';
import { featureFlagsContext } from '../../providers/FeatureFlagsProvider';
import ROUTES from '../../routes';
import SignIn from './SignIn';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(7, 12, 16, 12),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 2, 10, 2),
    },
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  card: {
    height: '100%',
    minWidth: 275,
    display: 'flex',
    flexDirection: 'column',
    padding: '1em 2em',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
}));

export interface Props {
  redirectUrl?: string;
  newEmail?: string;
  previousEmail?: string;
}

const SignInForm: FunctionComponent<Props> = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isPlannedDownTime } = useContext(featureFlagsContext);
  const { redirectUrl } = props;

  // if cookies are disabled, show warning
  if (typeof window !== 'undefined' && !window.navigator.cookieEnabled) {
    return <BrowserNotice />;
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid
        container
        className={classes.gridContainer}
        spacing={4}
        alignItems="stretch"
        justify="center"
      >
        <Grid item xs={12} md={7}>       
          <Card className={classes.card}>
            <Typography variant={'h1'}>{t('CUSTOMER_SIGN_IN')}</Typography>
            <SignIn {...props} />
          </Card>
        </Grid>
        {isPlannedDownTime ? null : (
          <Grid item xs={12} md={5}>
            <Card className={classes.card}>
              <Grid container spacing={1} className={classes.container}>
                <Grid item>
                  <Typography variant={'h1'}>{t('NEW_USER')}</Typography>
                </Grid>
                <Grid item>
                  <Typography>{t('CREATE_YOUR_ONLINE_ACCOUNT')}</Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row-reverse">
                    <Grid item>
                      <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={async () => {
                          await navigate(ROUTES.REGISTRATION, {
                            state: { redirectUrl: redirectUrl },
                          });
                        }}
                      >
                        {t('REGISTER')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default SignInForm;
