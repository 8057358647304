import { useLocation } from '@reach/router';
import React, { FC, useContext, useEffect } from 'react';
import SignInForm from '../../components/sign-in-form';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from '../../hooks/useTranslation';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import Helmet from 'react-helmet';
import { parse } from 'qs';
import CaliforniaUsers from '../../components/out-of-state/CaliforniaUsers';

type RouterState = {
  newEmail?: string;
  redirectUrl?: string;
  previousEmail?: string;
};

const SignInPage: FC = () => {
  const { t } = useTranslation();
  const { signOutWithoutRedirect } = useAuth();
  const title = t('CUSTOMER_SIGN_IN');
  const notificationContext = useContext(NotificationsContext);
  const location = useLocation();
  const routerLocationState = (location.state || {}) as RouterState;

  const queryParams: any = parse(location.search, { ignoreQueryPrefix: true });
  // newEmail can be set by e.g. the Change Email functionality.
  // If set, we show the success banner and set the email defaultValue in the form.
  const redirectUrl =
    routerLocationState?.redirectUrl || queryParams?.r || undefined;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    signOutWithoutRedirect();

    if (routerLocationState.newEmail !== undefined) {
      notificationContext.setState({
        isOpen: true,
        severity: 'success',
        message: t('EMAIL_CHANGED_SUCCESSFULLY'),
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SignInForm
        redirectUrl={redirectUrl}
        newEmail={routerLocationState.newEmail}
        previousEmail={routerLocationState.previousEmail}
      />
      <CaliforniaUsers />
    </>
  );
};

export default SignInPage;
